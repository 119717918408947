<template>
  <div>
    <div v-if="authUserPermission['bom-view']">
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0"></h6>
          </div>
        </div>
      </base-header>
      <div class="container-fluid mt--6">
        <div class="card">
            <div class="card-header mt--4 mb--4">
                <div class="row">
                    <div class="col">
                        <h3 class="mb-0">Bill of Material</h3>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="tableSearchBom" :placeholder="tt('search')" v-on:keyup="filterBom"/>                         
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <el-table
                  v-if="onLoad"
                  :data="bomData"
                  height="500px"
                  header-row-class-name="thead-light"
                  row-key="id"
                  border
                  lazy
                  :load="load"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                  <el-table-column label="Category" min-width="150px" sortable>
                    <template slot-scope="scope">
                      <span>{{ scope.row.bom_type }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Code" min-width="150px" sortable>
                    <template slot-scope="scope">
                      <span v-if="scope.row.equipment_code">{{ scope.row.equipment_code }} - {{ scope.row.equipment_name }}</span>
                      <span v-else>{{ scope.row.bom_name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="description" label="Description" min-width="450px" sortable></el-table-column>
                </el-table>
                <skeleton-loading v-else/>
            </div>
            <div class="card-footer pb-0">
                <custom-pagination :page-count="10" size="sm"></custom-pagination>
            </div>
        </div>
      </div>
    </div>
    <noaccess v-else/>
  </div>
</template>
<script>
  import bomMaster from '@/services/bom/bomMaster.service.js';
//   import bomType from '@/services/bom/bomType.service';
    import {mapState} from 'vuex'
  import Api from '@/helpers/api';
  export default {
    data() {
      return {
        currentBomCode: '',
        bomForm: {
          show:false,
          bomLoading: false,
          type: '',
        },
        charValueForm:{
          show: false,
          table: false,
          tableChar: false,
          loadingSave:false,
        },
        onLoad: false,
        bomData:[],
        tableSearchBom:'',
        bomCharValue:{
          code:[],
          bom_type_characteristic_code:[],
          bom_characteristic_name:[],
          single_or_multiple:[],
          characteristic_value:[],
          bom_code:[],
        },
        bom:{
          bom_code: '',
          bom_name:'',
          description: '',
          type: '',
          parent_code: 0,
          bom_level: 1,
        },
        selectSearch: {
          loading:false,
          loadTimeout:null,
        },
        bomTypes:[],
      }
    },
    computed: {
        ...mapState({
            authUserPermission :state => state.auth.userPermission
        }),
    },
    mounted(){
      this.get();
    },
    methods: {
      get(){
        let context = this;
        Api(context, bomMaster.get({search: context.tableSearchBom})).onSuccess((response) => {
          context.bomData = response.data.data.data;
        }).onError((error) =>{
          context.bomData = [];
        }).onFinish(() =>{
          context.onLoad = true
        }).call()
      },
      load(tree, treeNode, resolve) {
        setTimeout(() => {
          let context = this;
          Api(context, bomMaster.getChildren(tree.bom_code)).onSuccess((response) => {
            resolve(response.data.data);
          }).onError((error) =>{

          }).call()
        }, 100)
      },
      createChild(scope) {
        this.bomForm.type    = 'create'
        this.bomForm.show    = true
        this.bom.parent_code = scope.row.bom_code
        this.bom.bom_level   = scope.row.bom_level + 1
      },
      filterBom() {
        setTimeout(() => {                    
            this.get()
        }, 100);
      },
    },
  };
</script>
